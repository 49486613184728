import * as Sentry from '@sentry/react';
import branch from 'branch-sdk';
import LogRocket from 'logrocket';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { UserStore } from '../stores';

// TODO Move this to a helper file
export function waitForLibrary(property, callback) {
  if (window.hasOwnProperty(property)) {
    callback();
  } else {
    setTimeout(() => waitForLibrary(property, callback), 250);
  }
}

/**
 * Each time the user opens the app, we update their timestamp to use as a "last log in" time for precomputing the feed
 */
export const maybeUpdateUserTimestamp = () => {
  const currentUser = UserStore.getRawState().user;
  if (currentUser) {
    waitForLibrary('analytics', () => {
      if (window.analytics) {
        window.analytics.identify(currentUser.id, {
          email: currentUser.email,
          name: currentUser.full_name,
        });
      }
    });
  }
};

export const setUserIdentities = () => {
  const currentUser = UserStore.getRawState().user;
  if (currentUser) {
    Sentry.setUser({
      id: currentUser.id,
      email: currentUser.email,
    });

    if (window.OneSignal) {
      if (typeof window.OneSignal.setExternalUserId === 'function') {
        window.OneSignal.setExternalUserId(`${currentUser.id}`);
      }
      if (typeof window.OneSignal.setEmail === 'function') {
        if (currentUser.email) {
          window.OneSignal.setEmail(currentUser.email);
        }
      }
      if (typeof window.OneSignal.setSMSNumber === 'function') {
        if (currentUser.phone) {
          window.OneSignal.setSMSNumber(currentUser.phone);
        }
      }
    }

    waitForLibrary('analytics', () => {
      if (window.analytics) {
        window.analytics.identify(currentUser.id, {
          email: currentUser.email,
          name: currentUser.full_name,
        });
      }
    });

    // @deprecated
    waitForLibrary('fcWidget', () => {
      try {
        window.fcWidget.user.setProperties({
          firstName: currentUser.first_name,
          lastName: currentUser.last_name,
          email: currentUser.email,
          phone: currentUser.phone,
        });
      } catch(err) {
        console.error(err);
      }
    });

    waitForLibrary('tidioChatApi', () => {
      try {
        window.tidioChatApi.setVisitorData({
          distinct_id: currentUser.id,
          name: currentUser.full_name,
          email: currentUser.email,
          phone: currentUser.phone,
        });
        window.parent.postMessage({
          type: 'user_updated',
          user: currentUser,
        }, '*');
      } catch(err) {
        console.error(err);
      }
    });

    waitForLibrary('smartlook', () => {
      window.smartlook('identify', currentUser.id, {
        name: currentUser.full_name,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        email: currentUser.email,
        phone: currentUser.phone,
      });
    });

    waitForLibrary('__ls', () => {
      // INFO The don't track users on their free plan - we would need to upgrade for this to actually work
      window.__ls('identify', {
        name: currentUser.full_name,
        email: currentUser.email,
        params: {
          account_id: currentUser.id,
        },
      });
    });

    if (window.Capacitor.isNative) {
      BranchDeepLinks.setIdentity({ newIdentity: currentUser.id }).then((res) => {
        console.log('Branch identity set');
      }).catch((err) => {
        // ErrorHandler.log(err);
      });
    } else if (typeof branch.setIdentity === 'function') {
      branch.setIdentity(currentUser.id);
    }

    if (LogRocket && typeof LogRocket.identify === 'function') {
      LogRocket.identify(currentUser.id, {
        name: currentUser.full_name,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        email: currentUser.email,
        phone: currentUser.phone,
        image: currentUser.image_url,
      });
    }
  }
};
