import Product from '../models/Product';

const PushController = {
  // https://documentation.onesignal.com/docs/abandoned-cart
  addCartTags: (product, cart) => {
    const timestamp = Math.floor(Date.now() / 1000);
    window.OneSignalPlugin.sendTags({
      cart_update: timestamp,
      product_name: product.name,
      product_image: Product.getImage(product, false),
      cart_id: cart.token,
    });
  },
  removeCartTags: () => {
    window.OneSignalPlugin.sendTags({
      cart_update: '',
      product_name: '',
      product_image: '',
      cart_id: '',
    });
  },
  // TODO Include one for humidor entries here - to get users back in the app, we can prompt them to smoke the last cigar
  //  they added to their humidor in the same way the cart is handled above - when a user smokes a cigar from their humidor,
  //  it can update the "selected" cigar as the last one they entered still left in the list of entries, so if they happen to
  //  smoke the original last one, it gets replaced with the most current latest entry to prompt with.
  //  This is good to use if they've been absent from the app for X number of days etc
};
export default PushController;
