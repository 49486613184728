const postData = (data) => {
  try {
    window.parent.postMessage(data, '*');
  } catch (e) {
    console.error(e);
  }
};

const getPreference = (key) => {
  // FIXME We will need to pass localStorage down to the iframe from the wrapper on load to sync - this method needs
  //  to be synchronous and maintain state management functionality
  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  return null;
};

let native = getPreference('boxpressd_native_wrapper') === 'true' || getPreference('boxpressd_native_wrapper') === true;
let platform = getPreference('boxpressd_native_platform') || 'web';

// TODO Consider renaming this Storage and use it for all storage options, since it already is
/**
 * @deprecated Capacitor will be able to handle everything directly once the issue on Android is resolved
 * @type {{init: Transducer.init, setPlatform: Transducer.setPlatform, setData: Transducer.setData, removeListener: Transducer.removeListener, getPreference: (function(*=): (any|null)), isNative: (function(): boolean), removePreference: Transducer.removePreference, clearPreferences: Transducer.clearPreferences, setNative: Transducer.setNative, postMessage: Transducer.postMessage, setPreference: Transducer.setPreference, removeData: Transducer.removeData, getPlatform: (function(): any|string|string), getData: Transducer.getData, addListener: Transducer.addListener}}
 */
const Transducer = {
  init: (state) => {
    if (state) {
      postData({ type: 'sync_state', state });
    } else {
      const preferences = [];
      const keys = Object.keys(window.localStorage);
      let i = keys.length;
      while (i--) {
        preferences.push({ key: keys[i], value: localStorage.getItem(keys[i]) });
      }
      postData({ type: 'sync_state', state: preferences });
    }
  },
  isNative: () => native,
  setNative: (value) => { native = value; },
  getPlatform: () => platform,
  setPlatform: (value) => { platform = value; },
  postMessage: (type, params = {}) => {
    postData({ ...params, type });
  },
  setPreference: (key, value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      postData({
        type: 'store_preference',
        key,
        value,
      });
    } catch (e) {
      // Temporary fix for "QuotaExceededError: The quota has been exceeded"
      // We should really switch to something like IndexDB or another option that lets more data be stored locally
      console.error(e);
    }
  },
  // getPreference: async (key) => new Promise((resolve) => {
  //   const value = window.localStorage.getItem(key);
  //   if (value) {
  //     resolve(JSON.parse(value));
  //   }
  //   postData({ type: 'get_preference',
  //     key,
  //     callback: (data) => {
  //       resolve(data);
  //     },
  //   });
  // }),
  getPreference,
  removePreference: (key) => {
    window.localStorage.removeItem(key);
    postData({ type: 'remove_preference', key });
  },
  clearPreferences: () => {
    window.localStorage.clear();
    postData({ type: 'clear_preferences' });
  },
  setData: () => {
    // TODO Use sqlite
  },
  getData: () => {
    // TODO Use sqlite
  },
  removeData: () => {
    // TODO Use sqlite
  },
  addListener: (listener) => {
    window.addEventListener('message', listener);
  },
  removeListener: (listener) => {
    window.removeEventListener('message', listener);
  },
};

export { Transducer };
