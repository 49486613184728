import { config } from '../settings';

function redirect(url, replace = true, authPath = 'login') {
  // FIXME If source is passed in as query param, use that. For example, if Boxpressd sends traffic to CNM, Boxpressd is responsible for the sign up.
  const location = `${config.authEndPoint}/${authPath}?path=${encodeURI(url || window.location.href)}&source=cnm`;
  if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}

export function redirectAuth(url, replace = true) {
  redirect(url, replace, 'login');
}

export function register(url, replace = true) {
  redirect(url, replace, 'register');
}

export function redirectSignOut(replace = false) {
  const location = `${config.authEndPoint}/login?action=sign_out&source=cnm`;
  if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}
