export default function whenAvailable(name, callback) {
  const interval = 10; // ms
  window.setTimeout(() => {
    if (window[name]) {
      callback(window[name]);
    } else {
      whenAvailable(name, callback);
    }
  }, interval);
}
