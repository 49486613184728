import createPersistedState from 'use-persisted-state';
import { Transducer } from './transducer';
export function usePersistedState(key, defaultValue) {
  const useState = createPersistedState(key);
  const [state, setState] = useState(defaultValue);
  return [state, setState];
}

// FIXME Move to hooks folder
export function updatePersistedState(key, newValue) {
  // const useState = createPersistedState(key);
  // const [_, setState] = useState();
  // setState(newValue);
  Transducer.setPreference(key, newValue);
}
