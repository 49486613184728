const { BarcodeScanner } = window.Capacitor.Plugins;

export const didUserGrantPermission = async () => {
  // check if user already granted permission
  const status = await BarcodeScanner.checkPermission({
    force: true,
  });

  // alert(JSON.stringify(status));

  if (status.granted) {
    // user granted permission
    return true;
  }

  // FIXME It uses this even if set to "ask every time" on Android
  // https://github.com/capacitor-community/barcode-scanner/issues/66
  if (status.denied) {
    const c = confirm(
      'You must enable camera permissions to use the code scanner in system settings. Visit help.boxpressd.com for more information.'
    );
    if (c) {
      await BarcodeScanner.openAppSettings();
    } else {
      return false;
    }
  }

  if (status.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (status.neverAsked) {
    // user has not been requested this permission before
    // it is advised to show the user some sort of prompt
    // this way you will not waste your only chance to ask for the permission
    const c = confirm(
      'We need your permission to use your camera to be able to scan barcodes'
    );
    if (c) {
      await BarcodeScanner.openAppSettings();
    } else {
      return false;
    }
  }

  if (status.restricted || status.unknown) {
    // ios only
    // probably means the permission has been denied
    return false;
  }

  // user did not grant the permission, so he must have declined the request
  return false;
};

export const prepare = async () => {
  await BarcodeScanner.prepare();
};

// FIXME This needs a back button / UI overlay - can we load an iframe on top and use a URL passed by the front end?
export const startScan = async (options, callback) => {
  document.querySelector('body')?.classList.add('scanner-active');
  await BarcodeScanner.hideBackground();
  const result = await BarcodeScanner.startScan(options);
  if (result.hasContent) {
    if (typeof callback === 'function') {
      callback(result);
    }
    await stopScan();
  }
};

export const stopScan = async () => {
  document.querySelector('body')?.classList.remove('scanner-active');
  await BarcodeScanner.showBackground();
  await BarcodeScanner.stopScan();
};
